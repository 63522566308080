function downloadExcel(res,filename) {
    try{
        let blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
        let objectUrl = (window.URL || window.webkitURL).createObjectURL(blob)
        let downFile = document.createElement('a')
        downFile.style.display = 'none'
        downFile.href = objectUrl
        downFile.download = filename // 下载后文件名
        document.body.appendChild(downFile)
        downFile.click()
        document.body.removeChild(downFile)
        window.URL.revokeObjectURL(objectUrl) 
    }catch (e) {
        console.log('下载的文件出错',e)
    }
}
export default{
    downloadExcel
}