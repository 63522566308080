<template>
  <div>
    <div class="hotpost">
      <div class="top">
        <el-form
          :model="searchForm"
          :inline="true"
          ref="searchForm"
          label-width="100px"
        >
          <el-form-item label="标签名称:" prop="labelName">
            <el-input
              v-model="searchForm.labelName"
              placeholder="请输入标签名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="创建时间:" prop="CreateTime">
            <el-date-picker
              style="width: 300px"
              v-model="searchForm.CreateTime"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="线上状态:" prop="Status">
            <el-select
              v-model="searchForm.Status"
              style="width: 80px"
              @change="selectStatus"
            >
              <el-option
                v-for="item in AllStatus"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="置顶状态:" prop="IsOverHead">
            <el-select
              v-model="searchForm.IsOverHead"
              style="width: 100px"
              @change="selectIsOverHead"
            >
              <el-option
                v-for="item in AllIsOverHead"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetForm('searchForm')">重置</el-button>
            <el-button type="primary" @click="search(1)">查询</el-button>
          </el-form-item>
        </el-form>
        <el-button type="primary" style="margin: 0px 30px 10px" @click="addPage"
          >新增标签</el-button
        >
        <el-button @click="getExcel()">下载导入模板</el-button>
        <el-button @click="excelImport()">导入</el-button>
        <el-button @click="excelExport()">导出</el-button>
      </div>
      <el-table border style="width: 100%" :data="PageConfigure" row-key="Id">
        <el-table-column
          label="序号"
          width="80"
          align="center"
          type="index"
          sortable
        >
        </el-table-column>
        <el-table-column label="标签名称" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.LabelName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="标题" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.Title }}</span>
          </template>
        </el-table-column>
        <el-table-column label="关键词" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.KeyWord }}</span>
          </template>
        </el-table-column>
        <el-table-column label="描述" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.Description }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="文章数"
          width="100"
          prop="ArticleNum"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="创建时间"
          align="center"
          width="200"
          prop="CreateTime"
          sortable
        >
          <template slot-scope="scope">
            <span>{{ scope.row.CreateTime }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="线上状态"
          width="100"
          align="center"
          prop="Status"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.Status"
              :active-value="1"
              :inactive-value="0"
              active-text="显示"
              inactive-text="隐藏"
              class="SwitchStatus"
              @change="changeStatus(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column width="250" label="操作" align="center">
          <template slot-scope="scope">
            <span
              class="backSpan IsOverHead"
              @click="changeHtoLabel(scope.row)"
              :style="{ color: scope.row.IsOverHead ? '#ff3333' : '#5fb878' }"
            >
              {{ scope.row.IsOverHead ? "取消置顶" : "置顶" }}
            </span>
            <span
              class="backSpan"
              @click="eduit(scope.row)"
              style="color: #409eff"
            >
              编辑
            </span>
             <span
              class="backSpan"
              @click="addArticle(scope.row)"
              style="color: #ff9900"
            >
              添加文章
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="task-list-page" v-if="PageConfigure !== ''">
        <el-pagination
          @current-change="OnCurrentPageChanged"
          @size-change="handleSizeChange"
          :current-page="Params.PageIndex"
          :page-sizes="[10, 20, 30, 40, 50, 60]"
          layout="prev, pager, next,sizes,jumper,total"
          :page-size="Params.PageSize"
          background
          :total="Params.TotalCount"
        >
        </el-pagination>
      </div>
      <!-- 编辑 -->
      <div class="formdia">
        <el-dialog :modal-append-to-body="false" :visible.sync="dialogFormVisible" custom-class="edit-form">
          <span class="isedit">
            {{ IsEdit ? "编辑标签" : "新增标签" }}
          </span>
          <el-form :model="Rowcontent" :rules="rules" ref="editForm">
            <el-form-item
              label="标签名称"
              :label-width="formLabelWidth"
              prop="LabelName"
            >
               <el-input
                style="width: 340px"
                v-model="Rowcontent.LabelName"
                placeholder="请输入标签名称，不能超过20个字"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="页面标题"
              :label-width="formLabelWidth"
              prop="Title"
            >
               <el-input
                style="width: 340px"
                type="textarea"
                :rows="3"
                v-model="Rowcontent.Title"
                placeholder="请输入页面标题，不能超过30个字"
                maxlength="30"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="页面关键词"
              :label-width="formLabelWidth"
              prop="KeyWord"
            >
               <el-input
                style="width: 340px"
                type="textarea"
                :rows="3"
                v-model="Rowcontent.KeyWord"
                placeholder="请输入页面关键词，不能超过100个字"
                maxlength="100"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="页面描述"
              :label-width="formLabelWidth"
              prop="Description"
            >
               <el-input
                style="width: 340px"
                type="textarea"
                :rows="3"
                v-model="Rowcontent.Description"
                placeholder="请输入页面描述，不能超过150个字"
                maxlength="150"
              ></el-input>
            </el-form-item>
          </el-form>
              
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取消</el-button>
            <el-button :disabled="isDisabled" @click="submitForm('editForm')">
              确定
            </el-button>
          </div>
        </el-dialog>
      </div>
      <!-- 批量导入 -->
      <el-dialog
        :visible.sync="dialogImport"
        custom-class="edit-form"
        title="批量导入"
        :modal-append-to-body="false"
        >
          <div class="import_main">
           <div class="import_main_upload">
            <i>*</i>
            <span>上传文件</span>
            <span v-if="importExcelName.length" class="import_main_excel">{{importExcelName}}</span>
            <el-upload
              class="import_main_btn"
              :style="importExcelName?'width:90px;':'width:185px;'"
              action=""
              :before-upload="beforeUpload"
              :show-file-list="false"
              :http-request="(data)=>uploadFile(data.file)">
              {{importExcelName?'重新上传':'点击上传文件'}}
            </el-upload>
           </div>
           <div class="import_main_tips">
            温馨提示：<br/>
            1. 新增热点标签时名称不允许重复，如遇重复则视为修改操作；<br/>
            2. 常见失败原因有：必填项未完成填写、长度超出限制、非标准导入模板等；请确保数据无误后进行操作。
           </div>
          </div>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogImport=false">返回</el-button>
            <el-button type="primary" :disabled="isDisabled" @click="submitFile()">
              确定
            </el-button>
          </div>
        </el-dialog>
    </div>
  </div>
</template>
<script>
import down from "../../js/down";
import axios from "axios";
export default {
  data() {
    var LabelNameRules = (rule, value, callback) => {
      if (/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(value)) {
        callback();
      } else {
        callback(new Error("名称只允许输入汉字、数字或字母"));
      }
    };
    return {
      dialogFormVisible: false,
      dialogImport:false,
      formLabelWidth: "100px",
      Rowcontent: {
        Id: "",
        LabelName: "",
        Title:"",
        KeyWord: "",
        Description:"",
        ArticleNum: "",
        CreateTime: "",
        Status: "",
        IsOverHead: "",
      },
      rules: {
        LabelName: [
          { required: true, message: "请输入标签名称", trigger: "blur" },
          { validator: LabelNameRules, trigger: "blur" },
        ],
        Title: [
          { required: true, message: "请输入页面标题", trigger: "blur" },
        ],
        KeyWord: [
          { required: true, message: "请输入页面关键词", trigger: "blur" },
        ],
        Description: [
          { required: true, message: "请输入页面描述", trigger: "blur" },
        ],
      },
      //列表分页数据
      PageConfigure: [],
      Params: {
        PageIndex: 1,
        PageSize: 10,
        TotalCount: 0,
        TotalPage: 0,
      },
      IsApp: false,
      IsEdit: false,
      pageconfigID: "",
      pageName: "",
      deleteStatus: false,
      isDisabled: false,
      searchForm: {
        labelName: "",
        CreateTime: "",
        Status: "",
        IsOverHead: "",
      },
      //查询数据
      searchContent: {},
      //线上状态
      AllStatus: [
        { value: "", label: "全部" },
        { value: "1", label: "显示" },
        { value: "0", label: "隐藏" },
      ],
      //置顶状态
      AllIsOverHead: [
        { value: "", label: "全部" },
        { value: "1", label: "已置顶" },
        { value: "0", label: "未置顶" },
      ],
      //解决重复点击问题
      isDisabled: false,
      importExcelName:'',//导入excel的名字
      importExcelFile:'',//导入excel
      enableArr:[],//获取置顶个数
    };
  },
  created() {
    this.searchDate();
  },
  methods: {
    //获取数据
    searchDate() {
      var _this = this;
      this.enableArr=[];//重置置顶个数
      this.$get(this.$api.SearchHotLabel, {
        ..._this.Params,
      }).then((res) => {
        // console.log(res)
        _this.PageConfigure = res.Data;
        _this.Params.TotalCount = res.TotalNumber;
        _this.Params.TotalPage = res.TotalPage;
        if(_this.Params.TotalPage>=2){ //分页数大于1时
          for(let i=1;i<=_this.Params.TotalPage;i++){
          _this.Params.PageIndex = i;
          _this.$get(_this.$api.SearchHotLabel,{..._this.Params})
          .then((res)=>{
              let val = res.Data;
              val.forEach((item)=>{
              if(item.IsOverHead){
                _this.enableArr.push({id:item.Id});
              }
            })
          })
          }
        }else{
           _this.PageConfigure.forEach((item) => {
              if (item.IsOverHead) {
                _this.enableArr.push({ id: item.Id });
              }
            });
        }
        _this.Params.PageIndex = 1;
      });
    },
    //查询
    getSearchParams(){
      let _this =this;
      if ( this.searchForm.Status.trim() != "" && _this.searchForm.Status != null ) {
        _this.searchContent.status = this.searchForm.Status;
      }
      if ( this.searchForm.IsOverHead.trim() != "" && _this.searchForm.IsOverHead != null ) {
        _this.searchContent.isTop = this.searchForm.IsOverHead;
      }
      if ( _this.searchForm.labelName != null && _this.searchForm.labelName.trim() != "" ) {
        _this.searchContent.labelName = this.searchForm.labelName;
      }
      if (  _this.searchForm.CreateTime != null &&  _this.searchForm.CreateTime != 0 ) {
        _this.searchContent.startTime = this.searchForm.CreateTime[0];
        _this.searchContent.endTime = this.searchForm.CreateTime[1];
      }
    },
    search(num) {
      let _this = this;
      this.getSearchParams();
      if(num==1){
          this.Params.PageIndex=1;
        }
      this.$get(this.$api.SearchHotLabel,{
          ...this.searchContent,...this.Params
        }).then((res) => {
            _this.PageConfigure = res.Data;
            _this.searchContent = {};
            _this.Params.TotalCount = res.TotalNumber;
            _this.Params.TotalPage = res.TotalPage;
          }
        );
    },
    selectStatus(val) {
      var _this = this;
      _this.searchForm.Status = val;
    },
    selectIsOverHead(val) {
      var _this = this;
      _this.searchForm.IsOverHead = val;
    },

    //设置线上状态
    changeStatus(row) {
      // console.log(row.Id,row.Status);
      this.$post(
        this.$api.EnableHotLabel + "?id=" + row.Id + "&status=" + row.Status
      ).then((res) => {
        if (res.IsSuccess) {
          this.$message({
            message: "修改成功",
            type: "success",
            duration: "800",
          });
        }
      });
    },

    //设置置顶状态
    changeHtoLabel(row) {
      console.log(row.IsOverHead)
      let top;
      row.IsOverHead == true ? (top = 0) : (top = 1);
      if(!row.IsOverHead){
        if (this.enableArr.length >= 10) {
          this.$message({
            message: "已经达到开启的数量上限,取消部分置顶",
            type: "warning",
            duration: "1500",
          });
          return;
        }
      }
      this.$post(
        this.$api.OverheadHotLabel + "?id=" + row.Id + "&isTop=" + top
      ).then((res) => {
        if (res.IsSuccess) {
          if(row.IsOverHead){
            this.enableArr.forEach((item,index)=>{
              if(item.id==row.Id){
                this.enableArr.splice(index,1);
              }
          })
          }else{
             this.enableArr.push({ id: row.Id });
          }
          row.IsOverHead = !row.IsOverHead;
          this.$message({ message: "修改成功",type: "success",duration: "800"});
          // 修改状态成功后 重置搜索和表格数据
          this.searchForm= {
            labelName: "",
            CreateTime: "",
            Status: "",
            IsOverHead: "",
          }
          this.Params.PageIndex = 1;
          this.searchDate();
        }
      });
    },

    //新增
    addPage() {
      this.IsEdit = false;
      this.dialogFormVisible = true;
      this.Rowcontent.Id = 0;
      this.Rowcontent.LabelName = "";
      this.Rowcontent.Title = "";
      this.Rowcontent.KeyWord = "";
      this.Rowcontent.Description = "";
      this.Rowcontent.ArticleNum = 0;
      this.Rowcontent.CreateTime = new Date();
      this.Rowcontent.Status = "0";
      this.Rowcontent.IsOverHead = "true";
    },
    //编辑
    eduit(val) {
      this.IsEdit = true;
      this.dialogFormVisible = true;
      console.log(val);
      this.Rowcontent.Id = val.Id;
      this.Rowcontent.LabelName = val.LabelName;
      this.Rowcontent.Title = val.Title;
      this.Rowcontent.KeyWord = val.KeyWord;
      this.Rowcontent.Description = val.Description;
      this.Rowcontent.ArticleNum = val.ArticleNum;
      this.Rowcontent.CreateTime = val.CreateTime;
      this.Rowcontent.Status = val.Status;
      this.Rowcontent.IsOverHead = val.IsOverHead;
    },
    //新增、编辑的提交
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },
    submitForm(editForm) {
      this.isDisabled = true;
      this.entime();
      var _this = this;
      this.$refs[editForm].validate((valid) => {
        if (valid) {
          if (this.IsEdit) {
            console.log("编辑");
            // console.log(this.Rowcontent);
            this.$post(this.$api.UpdateHotLabel,this.Rowcontent).then((res) => {
              if (res.IsSuccess) {
                _this.$message({
                  message: "编辑成功",
                  type: "success",
                  duration: "1500",
                });
                _this.searchDate();
              }else{
                _this.$message({
                  message: res.Message,
                  type: "error",
                  duration: "1500",
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
            this.dialogFormVisible = false;
          } else {
            console.log("新增");
            // console.log(this.Rowcontent);
            this.$post(this.$api.AddHotLabel, this.Rowcontent)
              .then((res) => {
                if (res.IsSuccess) {
                  _this.$message({
                    message: "新增成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.searchDate();
                }else{
                  _this.$message({
                    message: res.Message,
                    type: "error",
                    duration: "1500",
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
            this.dialogFormVisible = false;
          }
        } else {
          this.$message({
            message: "请完善信息",
            type: "warning",
            duration: "1500",
          });
        }
      });
    },
    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      console.log(this.Params.PageIndex);
      this.search();
    },
    handleSizeChange(val) {
      this.Params.PageSize = val;
      console.log(val);
      this.search();
    },
    //重置查询
    resetForm(searchForm) {
      this.$refs[searchForm].resetFields();
      this.searchDate();
    },
    //下载excel导入模板
    getExcel(){
      // console.log('下载excel导入模板')
      axios.get(`${process.env.VUE_APP_ApiHost}/api/HotLabel/GetExcelTemplate`,{responseType:'blob'}
      ).then((res)=>{
        down.downloadExcel(res,'热点标签导入模板')
      }).catch((err)=>{
        console.log(err);
      })
    },
    //导入模板
    excelImport(){
      this.dialogImport = true;
      this.importExcelName = '';
      this.importExcelFile = '';
    },
    // 判断上传文件
    beforeUpload(file) {
      const isExcel = /\.(xlsx|xls)$/.test(file.name);
      if (!isExcel) {
        this.$message.error('只能上传.xlsx、.xls!');
        return false;
      }
      return true;
    },
    //上传
    uploadFile(file){
      // console.log(file)
      this.importExcelName = file.name;
      let formData = new FormData();
      formData.append("file", file);
      this.importExcelFile = formData;
    },
    //上传弹窗 确定
    submitFile(){
      if(!this.importExcelFile){
        this.$message.error('请先上传文件');
        return;
      }
      this.$post(this.$api.ImportHotLabel,this.importExcelFile).then(res=>{
        if(res.IsSuccess) {
          this.dialogImport = false;
          this.$message.success(res.Message);
          this.searchDate();
        } else {
          this.$message.error(res.Message);
        }
      })  
    },
    //导出模板
    excelExport(){
      let _this = this;
      // console.log('导出模板')
      this.getSearchParams();
      axios.get(`${process.env.VUE_APP_ApiHost}/api/HotLabel/ExportHotLabelExcel`,{
        responseType:'blob',
        params:_this.searchContent
      }).then((res)=>{
        down.downloadExcel(res,'热点标签')
      }).catch((err)=>{
        console.log(err);
      })
    },
    addArticle(row){
      // console.log(row)
      this.$router.push({ path: "/LabelAddArticle",query:{id:row.Id,labelName:row.LabelName} });
    }
  },
};
</script>
<style lang="stylus" scoped>
/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.hotpost {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;

  .top {
    padding-left: 10px;
  }

  .backSpan {
    padding: 0px 10px;
    cursor: pointer;
  }

  .task-list-page {
    margin-top: 10px;
  }

  /deep/.SwitchStatus {
    .el-switch__label {
      position: absolute;
      display: none;
    }

    .el-switch__core:after {
      left: 4px;
    }

    /* 打开时文字位置设置 */
    .el-switch__label--right {
      z-index: 1;
      right: 25px;
      color: #fff;
    }

    /* 关闭时文字位置设置 */
    .el-switch__label--left {
      z-index: 1;
      left: 25px;
      color: #606266;
    }

    /* 显示文字 */
    .el-switch__label.is-active {
      display: block;
    }
  }

  // 打开时按钮位置
  /deep/.SwitchStatus.el-switch.is-checked .el-switch__core::after {
    left: 100%;
    margin-left: -20px;
  }

  /deep/.SwitchStatus.el-switch .el-switch__core, .el-switch .el-switch__label {
    width: 70px !important; /* 开关按钮的宽度大小 */
  }
}

/* 编辑框样式 */
/deep/.edit-form {
  span.isedit {
    position: absolute;
    top: 10px;
    left: 210px;
    font-size: 22px;
    color: #fff;
  }

  width: 500px;

  .el-dialog__header {
    height: 50px;
    text-align: center;
    background-color: #409eff;

    .el-dialog__title {
      font-size: 22px;
      line-height:0;
      color: #fff;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-form-item .el-textarea__inner {
    resize: none;
  }

  .el-dialog__body {
    padding-bottom: 5px !important;
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      background-color: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
    }

    .el-button:hover {
      background-color: #409eff;
      border-color: #409eff;
      color: #fff;
    }
  }
}
/* 导入弹窗样式 */
.import_main{
  width:350px;
  margin:25px auto 0;
  .import_main_upload{
    display: flex;
    justify-content: center;
    align-items: center
    i{
      color:#ff0000;
    }
    span{
      font-size:14px;
      color:#333;
      margin-left:5px;
    }
    .import_main_excel{
      max-width: 160px;
      color:#409eff;
      margin-left: 15px;      
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .import_main_btn{
      height: 40px;
      font-size:14px;
      color:#409eff;
      line-height: 40px;
      text-align:center;
      border: 1px solid #409eff;
      border-style: dashed;
      border-radius:5px;
      margin-left:15px;
      &:hover{
        cursor:pointer;
        background:#e6f5ff;
      }
    }
  }
  .import_main_tips{
    font-size: 12px;
    font-weight: 400;
    color:#aaa;
    line-height:20px;
    margin-top:15px;
  }
}
</style>